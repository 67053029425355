@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/mixin';
@import '../../../styles/breakpoints';

.upload-file-wrapper {
  @include backgroundOpacity($white, 0.6);
  border: 2px dashed map-get($brand, orange);
  border-radius: $radius-small;

  .uploadFile {
    > input {
      display: none;
    }

    @include mobile-breakpoint {
      padding: 20px 25px;
    }

    .button {
      margin-top: 16px;
    }

    &.detailed {
      padding: 42px 50px 33px;
      text-align: center;

      > .icon {
        width: 100%;
      }
    }

    &.simple {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 8px;
      height: 80px;
      padding: 12px 16px;
    }

    .colored-text {
      color: map-get($brand, orange);
      cursor: pointer;
    }
  }

  &.disabled {
    background-color: map-get($brand, beige);
    border-color: map-get($beige, 1000);
    pointer-events: none;

    .upload-file-text {
      & > p {
        color: map-get($beige, 1000);
      }
    }
  }
}
