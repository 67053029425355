.contract-files-upload {
  width: 100%;

  & .upload-file-wrapper {
    margin-top: 6px;
  }
}

.contract-file-display {
  margin-top: 10px;
  height: 56px;
}
