@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/mixin';
@import '../../styles/breakpoints';

.snackbar-wrapper {
  margin-top: 40px;
  padding: 22px 32px;
  display: flex;
  justify-content: space-between;

  .sak-text {
    color: $white;
  }

  @include tablet-breakpoint {
    margin-top: 20px;
  }

  @include mobile-breakpoint {
    margin-top: 20px;
  }

  .snackbar-text-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    h3 {
      margin-bottom: 4px;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    flex: none;

    path {
      fill: $white;
    }
  }

  &.error {
    background-color: map-get($status, error);
  }

  &.success {
    background-color: map-get($status, success);
  }

  &.info {
    background-color: map-get($status, info);
  }
}

.close-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  @include mobile-breakpoint {
    span:first-child {
      display: none;
    }
  }

  @include tablet-breakpoint {
    span:first-child {
      display: none;
    }
  }

  .icon-close {
    cursor: pointer;

    & path {
      fill: $white;
    }
  }
}

.snackbar-wrapper.close {
  display: none;
}
