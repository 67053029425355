@import '../../styles/colors';
@import '../../styles/breakpoints';

.personal-data-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include mobile-breakpoint {
    width: 100%;
  }

  @include tablet-breakpoint {
    width: 100%;
  }

  @include lg-breakpoint {
    width: 100%;
  }

  > button.button.large {
    align-self: flex-start;
    padding: 18px 32px;
  }

  .user-name-wrapper {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);

    @include mobile-breakpoint {
      display: flex;
      flex-direction: column;
    }

    @include tablet-breakpoint {
      display: flex;
      flex-direction: column;
    }
  }

  .user-address {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

    > div:first-child {
      grid-column: 1 / 4;
    }

    > div:last-child {
      grid-column: 2 / 5;
    }
  }

  .delete-account-text {
    color: map-get($green, 1000);
    .contact-link {
      cursor: pointer;
      color: map-get($brand, green);
    }
  }
}
