.contract-cancelation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 24px;
  width: 60%;
  margin-top: 20px;

  & .dropdown-list-wrapper {
    margin-top: 6px;
    min-width: 480px;
  }
}

.children-wrapper {
  .cancel-contract-buttons {
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
  }
}
