.credit-cancellation-wrapper {
  width: 60%;
  display: flex;
  flex-direction: column;
  row-gap: 36px;

  .credit-cancellation {
    display: flex;
    flex-direction: column;
    row-gap: 50px;

    > div {
      display: flex;
      flex-direction: column;
      row-gap: 32px;
    }

    .contract-cancelation-wrapper {
      width: 100%;
      margin-top: 0;
    }
  }
}
