@import '../../styles/colors';

.contract-status-field-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  .circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: map-get($state, inactive-font);
    border-radius: 50%;

    &.notValid {
      background-color: map-get($status, error);
    }

    &.valid {
      background-color: map-get($status, success);
    }
  }
}
