@import '../../../../../styles/breakpoints';

.contract-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 32px;
  width: 60%;

  @include lg-breakpoint {
    width: 100%;
  }

  @include tablet-breakpoint {
    width: 100%;
  }

  @include mobile-breakpoint {
    width: 100%;
  }

  .contract-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 24px;
    width: 100%;

    .contract-header {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;

      @include mobile-breakpoint {
        display: flex;
        flex-direction: column;
      }

      > div {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
      }

      .dropdown-list-wrapper {
        height: 80px;

        @include tablet-breakpoint {
          height: 64px;
        }

        @include mobile-breakpoint {
          height: 64px;
        }
      }
    }

    .dropdown-list-wrapper {
      height: 80px;
    }
  }

  .contract-files {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
}
