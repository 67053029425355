@import '../../styles/breakpoints';

.contract-signatures {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  width: 100%;

  @include mobile-breakpoint {
    display: flex;
    flex-direction: column;
  }

  .signature-date {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  }
}
